/*Variables*/

:root {
  --main-theme: #2a2f3d;
  --yellow-theme: #fdca7f;
  --green-theme: #96ff81;
  --red-theme: #ffa3a3;
  --max-width: 3000px;
  --spacing: 0.1rem;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

/*Global Styles*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
::placeholder {
  color: #000;
  opacity: 1;
  font-family: inherit;
}

:-ms-input-placeholder {
  color: #000;
}

::-ms-input-placeholder {
  color: #000;
}
body {
  font-family: "Roboto Mono", monospace;
  background: var(--main-theme);
  color: #fff;
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: inherit;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
  color: #fff;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: #fff;
}
.yellow {
  color: var(--yellow-theme);
}
.yellow-bg {
  background-color: var(--yellow-theme);
}

.green {
  color: var(--green-theme);
}
.green-bg {
  background-color: var(--green-theme);
}
.red {
  color: var(--red-theme);
}
.red-bg {
  background-color: var(--red-theme);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  Global Classes */

.App {
  max-width: 2500px;
  margin: auto;
}

section {
  display: flex;
  flex-direction: row;
}

section div {
  width: 50%;
}

section div.text {
  padding: 0px;
}

section div.full-image {
  background-image: url("../../images/IMG_0188.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

section div.image-2 {
  background-image: url("../../images/IMG_0290.jpg");
  height: 100vh;
}

.sub-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.social-link:hover {
  color: #fff;
}

@media (max-width: 767px) {
  section {
    flex-direction: column;
    width: 100%;
  }

  section div {
    width: 100%;
  }

  section div.full-image {
    height: 60vw;
    width: 100vw;
    background-position: 50% 25%;
    position: relative;
    top: auto;
    padding: 0;
    margin: 0;
  }

  section div.image-2 {
    height: 50vw;
  }
}
