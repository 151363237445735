.tech-summary {
  padding: 1rem 2rem;
}

.tech-icons {
  display: grid;
  grid-template-columns: auto auto auto;
  font-size: 50px;
  column-gap: 5px;
  row-gap: 15px;
  width: 100%;
  justify-items: center;
  align-items: center;
}

.main-area {
  grid-template-columns: auto auto auto auto;
}

.tech-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
}

.tech-icon-logo {
  font-size: 75px;
}

.tech-icon-name {
  font-size: 1rem;
  padding-top: 0.5rem;
  text-align: center;
  word-spacing: 0.1px;
}

.tech-future,
.tech-exp {
  text-align: justify;
  padding: 2rem 0;
  margin: 0;
}

@media (max-width: 800px) {
  .tech-icon-logo {
    font-size: 50px;
  }
  .tech-icon-name {
    font-size: 0.67rem;
  }
}

@media (min-width: 1500px) {
  .tech-summary {
    padding: 2rem 3rem;
  }
}

@media (max-width: 500x) {
  .tech-summary {
    padding: 1rem;
  }
}
