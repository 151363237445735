.cards {
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #fff;
  color: #000;
}

.card-container {
  flex-direction: row-reverse;
}

.cards-summary {
  padding: 0.75rem;
  color: #000;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cards-summary::-webkit-scrollbar {
  display: none;
}

.sub-heading-cards {
  color: #000;
  width: 100%;
  text-align: center;
}

.image-link{
  display: flex;
  justify-content: center;
  width: fit-content;
}

.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid var(--main-theme);
  border-radius: 5px;
  margin: 1rem auto;
  background-color: var(--main-theme);
  cursor: pointer;
}

.project-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.project-title {
  margin: 0;
  padding: 0;
}

.project-title-left {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.project-button {
  border-radius: 50%;
  font-size: 1.5rem;
}

.project-tech {
  display: flex;
  justify-content: flex-start;
  margin-left: 4px;
}

.project-tech-list {
  padding: 2px;
  margin: 3px;
  background-color: #fff;
  font-weight: bolder;
  font-size: 0.75rem;
  border-radius: 2px;
}

.card-content {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  width: 100%;
  margin: auto;
}

.card-image {
  width: 50%;
  height: auto;
  border: 1px solid #fff;
  margin: 5px 10px;
}

.card-desc {
  text-align: left;
  padding: 15px;
  font-size: 1rem;
}

.card-links {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 10px;
}

.card-links a {
  color: inherit;
  font-size: 1.7rem;
}
@media (max-width: 850px) {
  .project-title-left {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .card-container {
    flex-direction: column;
  }

  .cards {
    position: relative;
    top: auto;
    height: auto;
  }

  .card-image {
    width: 70vw;
    height: auto;
  }

  .card-links a {
    font-size: 2.5rem;
  }
}
