.carousel {
  padding: 0.75rem;
  width: 100%;
  height: 250px;
  overflow-x: hidden;
}

#carousel-title {
  color: var(--main-theme);
}

.caro-card {
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.caro-card span {
  text-align: left;
  font-size: 1rem;
  padding: 2px 10px;
}

.caro-desc {
  text-align: left;
  width: 100%;
  font-size: 0.9rem;
}

.caro-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  text-align: left;
  padding: 10px 10px;
  width: 100%;
  height: 100%;
}

.caro-content-links {
  position: absolute;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.caro-content-links a {
  font-size: 1.5rem;
  margin-right: 5px;
}

.swiper-container {
  width: 100vw;
  height: auto;
  /* padding: 20px; */

  align-items: center;
}

.swiper-wrapper {
  margin: 0 55px;
  width: 100%;
  height: 150px !important;
  align-items: center;
}

.swiper-slide {
  text-align: center;
  height: 150px !important;
  cursor: pointer;
  background: var(--main-theme);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

.swiper-button-next {
  right: 30px !important;
}

@media (max-width: 767px) {
  .swiper-slide {
    width: 200px !important;
  }
}
