@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
/*Variables*/

:root {
  --main-theme: #2a2f3d;
  --yellow-theme: #fdca7f;
  --green-theme: #96ff81;
  --red-theme: #ffa3a3;
  --max-width: 3000px;
  --spacing: 0.1rem;
}

/*Global Styles*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
  font-family: inherit;
}
:-ms-input-placeholder {
  color: #000;
  opacity: 1;
  font-family: inherit;
}
::placeholder {
  color: #000;
  opacity: 1;
  font-family: inherit;
}

:-ms-input-placeholder {
  color: #000;
}

::-ms-input-placeholder {
  color: #000;
}
body {
  font-family: "Roboto Mono", monospace;
  background: #2a2f3d;
  background: var(--main-theme);
  color: #fff;
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: inherit;
}
h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
  color: #fff;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: #fff;
}
.yellow {
  color: #fdca7f;
  color: var(--yellow-theme);
}
.yellow-bg {
  background-color: #fdca7f;
  background-color: var(--yellow-theme);
}

.green {
  color: #96ff81;
  color: var(--green-theme);
}
.green-bg {
  background-color: #96ff81;
  background-color: var(--green-theme);
}
.red {
  color: #ffa3a3;
  color: var(--red-theme);
}
.red-bg {
  background-color: #ffa3a3;
  background-color: var(--red-theme);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  Global Classes */

.App {
  max-width: 2500px;
  margin: auto;
}

section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

section div {
  width: 50%;
}

section div.text {
  padding: 0px;
}

section div.full-image {
  background-image: url(/static/media/IMG_0188.6967eabb.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

section div.image-2 {
  background-image: url(/static/media/IMG_0290.a365bb69.jpg);
  height: 100vh;
}

.sub-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.social-link:hover {
  color: #fff;
}

@media (max-width: 767px) {
  section {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }

  section div {
    width: 100%;
  }

  section div.full-image {
    height: 60vw;
    width: 100vw;
    background-position: 50% 25%;
    position: relative;
    top: auto;
    padding: 0;
    margin: 0;
  }

  section div.image-2 {
    height: 50vw;
  }
}

nav {
  position: fixed;
  top: 1%;
  z-index: 10;
  right: 4%;
  color: #000;
  padding: 10px;
}

.icon-m {
  padding: 10px;
  border-radius: 50%;
  color: #000;
  display: grid;
  place-content: center;
  border: 2px solid var(--main-theme);
  cursor: pointer;
}

@-webkit-keyframes clip-path {
  0% {
    -webkit-clip-path: circle(2.2% at 88% 9%);
            clip-path: circle(2.2% at 88% 9%);
  }
  25% {
    -webkit-clip-path: circle(59.7% at 88% 9%);
            clip-path: circle(59.7% at 88% 9%);
  }
  50% {
    -webkit-clip-path: circle(109.6% at 100% 0);
            clip-path: circle(109.6% at 100% 0);
  }
  75% {
    -webkit-clip-path: circle(131.6% at 100% 0);
            clip-path: circle(131.6% at 100% 0);
  }
  100% {
    -webkit-clip-path: circle(141.4% at 74% 31%);
            clip-path: circle(141.4% at 74% 31%);
  }
}

@keyframes clip-path {
  0% {
    -webkit-clip-path: circle(2.2% at 88% 9%);
            clip-path: circle(2.2% at 88% 9%);
  }
  25% {
    -webkit-clip-path: circle(59.7% at 88% 9%);
            clip-path: circle(59.7% at 88% 9%);
  }
  50% {
    -webkit-clip-path: circle(109.6% at 100% 0);
            clip-path: circle(109.6% at 100% 0);
  }
  75% {
    -webkit-clip-path: circle(131.6% at 100% 0);
            clip-path: circle(131.6% at 100% 0);
  }
  100% {
    -webkit-clip-path: circle(141.4% at 74% 31%);
            clip-path: circle(141.4% at 74% 31%);
  }
}

.menu-wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 10px;
  -webkit-animation: clip-path 1s linear;
          animation: clip-path 1s linear;
  border: 0.2px solid var(--main-theme);
}

.icon-x {
  padding: 9px;
  width: 140px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.nav-drop {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  padding: 0px 30px 10px;
}

.nav-links {
  cursor: pointer;
}

.colours {
  display: -webkit-flex;
  display: flex;
  padding: 0;
  -webkit-justify-content: center;
          justify-content: center;
}

.themes {
  margin-top: 10px;
  cursor: default imp !important;
}
.yellow-btn,
.red-btn,
.green-btn,
.about-btn {
  padding: 15px;
  border-radius: 50%;
  margin: 2px;
  cursor: pointer;
}

.about-btn {
  background-color: #fc4445;
}

.about-control {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 auto;
}

article {
  position: relative;
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: left;
}

.logo {
  position: absolute;
  cursor: pointer;
  top: 3%;
  left: 4%;
  text-transform: uppercase;
  padding: 0.2rem 0.4rem;
  -webkit-transform: skew(5deg, -5deg);
          transform: skew(5deg, -5deg);
  border: 2px solid #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logo:hover {
  -webkit-transform: skew(-5deg, 5deg);
          transform: skew(-5deg, 5deg);
}

.centre {
  padding-top: 0px;
}

.title {
  margin: 2rem 0;
  font-size: 3rem;
}

.small-heading {
  font-size: 1rem;
  padding: 0 30px;
  margin: 0 auto;
  text-align: left;
}

.strap-line {
  width: 100%;
}

.title-roll {
  width: 100%;
  text-align: right;
}

.quick-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 3rem 0;
}

.quick-link {
  cursor: pointer;
  position: relative;
}

.quick-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.quick-link:hover::before {
  visibility: visible;
  width: 100%;
}

.quick-social {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin: 1rem 0;
}

.quick-social a {
  margin: 0.2rem;
  font-size: 1.5rem;
}

.quick-page-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
}

.down-icon {
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 5%;
  font-size: 50px;
  -webkit-animation: bounce infinite 1s;
          animation: bounce infinite 1s;
  cursor: pointer;
}

.particles-full {
  position: absolute;
  width: 95vw;
  height: 100vh;
  z-index: -5;
  overflow: hidden;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@media (max-width: 767px) {
  .centre {
    padding: 0px 30px;
  }

  .title {
    text-align: center;
    font-size: 2.5rem;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 2rem;
  }
}

@media (min-width: 1500px) {
  .title {
    font-size: 3.5rem;
  }

  .small-heading {
    font-size: 2rem;
  }

  .title-roll {
    font-size: 1.3rem;
  }

  .quick-social a {
    font-size: 2.5rem;
  }

  .quick-link {
    font-size: 1.3rem;
  }
}

.about-summary {
  padding: 1rem 2rem;
}

legend {
  width: 100%;
}

.radio-div {
  width: auto;
}

.thin-para {
  padding: 0;
  margin: 0;
}

.radio-field {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: center;
  outline: none;
  border: none;
  padding: 5px;
}
.radio-field label {
  width: 0%;
  color: var(--main-theme);
}

.fields {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 2rem auto;
}
.labels {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}



@media (min-width: 1500px) {
  .about-summary {
    padding: 2rem 4rem;
  }
}

@media (max-width: 500px) {

  .fields {
    width: 70vw;
  }
}

.tech-summary {
  padding: 1rem 2rem;
}

.tech-icons {
  display: grid;
  grid-template-columns: auto auto auto;
  font-size: 50px;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  grid-row-gap: 15px;
  row-gap: 15px;
  width: 100%;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
}

.main-area {
  grid-template-columns: auto auto auto auto;
}

.tech-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: xx-large;
}

.tech-icon-logo {
  font-size: 75px;
}

.tech-icon-name {
  font-size: 1rem;
  padding-top: 0.5rem;
  text-align: center;
  word-spacing: 0.1px;
}

.tech-future,
.tech-exp {
  text-align: justify;
  padding: 2rem 0;
  margin: 0;
}

@media (max-width: 800px) {
  .tech-icon-logo {
    font-size: 50px;
  }
  .tech-icon-name {
    font-size: 0.67rem;
  }
}

@media (min-width: 1500px) {
  .tech-summary {
    padding: 2rem 3rem;
  }
}

@media (max-width: 500x) {
  .tech-summary {
    padding: 1rem;
  }
}

.cards {
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #fff;
  color: #000;
}

.card-container {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.cards-summary {
  padding: 0.75rem;
  color: #000;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cards-summary::-webkit-scrollbar {
  display: none;
}

.sub-heading-cards {
  color: #000;
  width: 100%;
  text-align: center;
}

.image-link{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.project-card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  border: 1px solid var(--main-theme);
  border-radius: 5px;
  margin: 1rem auto;
  background-color: var(--main-theme);
  cursor: pointer;
}

.project-title-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.project-title {
  margin: 0;
  padding: 0;
}

.project-title-left {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.project-button {
  border-radius: 50%;
  font-size: 1.5rem;
}

.project-tech {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 4px;
}

.project-tech-list {
  padding: 2px;
  margin: 3px;
  background-color: #fff;
  font-weight: bolder;
  font-size: 0.75rem;
  border-radius: 2px;
}

.card-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: auto;
}

.card-image {
  width: 50%;
  height: auto;
  border: 1px solid #fff;
  margin: 5px 10px;
}

.card-desc {
  text-align: left;
  padding: 15px;
  font-size: 1rem;
}

.card-links {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 10px;
}

.card-links a {
  color: inherit;
  font-size: 1.7rem;
}
@media (max-width: 850px) {
  .project-title-left {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 767px) {
  .card-container {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .cards {
    position: relative;
    top: auto;
    height: auto;
  }

  .card-image {
    width: 70vw;
    height: auto;
  }

  .card-links a {
    font-size: 2.5rem;
  }
}

.carousel {
  padding: 0.75rem;
  width: 100%;
  height: 250px;
  overflow-x: hidden;
}

#carousel-title {
  color: var(--main-theme);
}

.caro-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: left;
          justify-content: left;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.caro-card span {
  text-align: left;
  font-size: 1rem;
  padding: 2px 10px;
}

.caro-desc {
  text-align: left;
  width: 100%;
  font-size: 0.9rem;
}

.caro-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  text-align: left;
  padding: 10px 10px;
  width: 100%;
  height: 100%;
}

.caro-content-links {
  position: absolute;
  bottom: 2%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 100%;
  height: 100%;
}

.caro-content-links a {
  font-size: 1.5rem;
  margin-right: 5px;
}

.swiper-container {
  width: 100vw;
  height: auto;
  /* padding: 20px; */

  -webkit-align-items: center;

          align-items: center;
}

.swiper-wrapper {
  margin: 0 55px;
  width: 100%;
  height: 150px !important;
  -webkit-align-items: center;
          align-items: center;
}

.swiper-slide {
  text-align: center;
  height: 150px !important;
  cursor: pointer;
  background: var(--main-theme);
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

.swiper-button-next {
  right: 30px !important;
}

@media (max-width: 767px) {
  .swiper-slide {
    width: 200px !important;
  }
}

.contact-summary {
  position: relative;
  padding: 0.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

.contact-desc {
  text-align: center;
  padding: 1rem;
}

.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
}

form p {
  margin-bottom: 0.75rem;
  margin-right: 2px;
}

textarea {
  box-sizing: border-box;
  width: 30vw;
  resize: none;
  padding: 0.5rem;
  font-family: inherit;
}

textarea:focus {
  background-color: #fff;
  outline: none;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::placeholder {
  color: transparent;
}

.contact-label {
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  padding: 0.5rem;
}

.contact-label:focus {
  background-color: #fff;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}

.contact-btn-div {
  text-align: left;
  width: 100%;
  margin-bottom: 4rem;
}

.contact-btn {
  color: #000;
  border: 1px solid var(--main-theme);
  outline: none;
  width: 75px;
  height: 40px;
  cursor: pointer;
  transition: 0.5s ease;
  font-weight: bolder;
}

.contact-btn:hover {
  background-color: #fff;
  -webkit-transform: translate(0.25em, -0.25em);
          transform: translate(0.25em, -0.25em);
}

.download-btn {
  color: #000;
  margin-top: 10px;
  width: 150px;
  border: 1px solid var(--main-theme);
  outline: none;
  height: 40px;
  cursor: pointer;
  transition: 0.5s ease;
  font-weight: bolder;
}

.download-btn:hover {
  -webkit-animation: flicker 1s;
          animation: flicker 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.33));
          filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.33));
}

@-webkit-keyframes flicker {
  0% {
    opacity: 1;
  }
  8% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  32% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  48% {
    opacity: 1;
  }
  to {
    opacity: 1;
    background-color: #fff;
    color: #000;
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  8% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  32% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  48% {
    opacity: 1;
  }
  to {
    opacity: 1;
    background-color: #fff;
    color: #000;
  }
}

.contact-socials {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 40px;
}

.quick-social-contact {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 1rem;
  margin-bottom: 25px;
}

.quick-social-contact a {
  font-size: 30px;
}

.footer {
  width: 100%;
  margin: 0.5rem auto;
  text-align: center;
}

.contact-footer {
  padding: 0 1rem 1rem 1rem;
  text-align: center;
}

.footer p {
  padding: 0;
  margin: 0;
}

.name-span {
  cursor: pointer;
}

.resume {
  padding: 0 1rem 1rem 1rem;
  text-align: center;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
}

.success-form {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: auto;
  margin: 0px auto 30px auto;
  padding: 0;
}

.success-form p {
  text-align: center;
  font-size: 1.2rem;
  margin: 10px auto;
}

.yellow-stroke {
  stroke: var(--yellow-theme);
}

.green-stroke {
  stroke: var(--green-theme);
}

.red-stroke {
  stroke: var(--red-theme);
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 8;
  stroke-miterlimit: 10;
  fill: var(--main-theme);
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 2% auto;
  box-shadow: 0px 0px 0px;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
          animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}
@-webkit-keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}

@media (max-width: 767px) {
  .footer p {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }

  .contact-label {
    width: 70vw;
    min-width: 265px;
  }
  textarea {
    width: 70vw;
    min-width: 265px;
  }
}

@media (max-width: 1200px) {
  .contact-form {
    margin: 0 auto;
  }

  .resume {
    margin: 0 auto;
  }
}

