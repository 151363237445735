article {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.logo {
  position: absolute;
  cursor: pointer;
  top: 3%;
  left: 4%;
  text-transform: uppercase;
  padding: 0.2rem 0.4rem;
  transform: skew(5deg, -5deg);
  border: 2px solid #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logo:hover {
  transform: skew(-5deg, 5deg);
}

.centre {
  padding-top: 0px;
}

.title {
  margin: 2rem 0;
  font-size: 3rem;
}

.small-heading {
  font-size: 1rem;
  padding: 0 30px;
  margin: 0 auto;
  text-align: left;
}

.strap-line {
  width: 100%;
}

.title-roll {
  width: 100%;
  text-align: right;
}

.quick-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
}

.quick-link {
  cursor: pointer;
  position: relative;
}

.quick-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.quick-link:hover::before {
  visibility: visible;
  width: 100%;
}

.quick-social {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.quick-social a {
  margin: 0.2rem;
  font-size: 1.5rem;
}

.quick-page-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.down-icon {
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 5%;
  font-size: 50px;
  animation: bounce infinite 1s;
  cursor: pointer;
}

.particles-full {
  position: absolute;
  width: 95vw;
  height: 100vh;
  z-index: -5;
  overflow: hidden;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 767px) {
  .centre {
    padding: 0px 30px;
  }

  .title {
    text-align: center;
    font-size: 2.5rem;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 2rem;
  }
}

@media (min-width: 1500px) {
  .title {
    font-size: 3.5rem;
  }

  .small-heading {
    font-size: 2rem;
  }

  .title-roll {
    font-size: 1.3rem;
  }

  .quick-social a {
    font-size: 2.5rem;
  }

  .quick-link {
    font-size: 1.3rem;
  }
}
