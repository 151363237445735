.contact-summary {
  position: relative;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.contact-desc {
  text-align: center;
  padding: 1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

form p {
  margin-bottom: 0.75rem;
  margin-right: 2px;
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 30vw;
  resize: none;
  padding: 0.5rem;
  font-family: inherit;
}

textarea:focus {
  background-color: #fff;
  outline: none;
}

textarea:focus::placeholder {
  color: transparent;
}

.contact-label {
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  padding: 0.5rem;
}

.contact-label:focus {
  background-color: #fff;
}

input:focus::placeholder {
  color: transparent;
}

.contact-btn-div {
  text-align: left;
  width: 100%;
  margin-bottom: 4rem;
}

.contact-btn {
  color: #000;
  border: 1px solid var(--main-theme);
  outline: none;
  width: 75px;
  height: 40px;
  cursor: pointer;
  transition: 0.5s ease;
  font-weight: bolder;
}

.contact-btn:hover {
  background-color: #fff;
  transform: translate(0.25em, -0.25em);
}

.download-btn {
  color: #000;
  margin-top: 10px;
  width: 150px;
  border: 1px solid var(--main-theme);
  outline: none;
  height: 40px;
  cursor: pointer;
  transition: 0.5s ease;
  font-weight: bolder;
}

.download-btn:hover {
  animation: flicker 1s;
  animation-fill-mode: forwards;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.33));
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  8% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  32% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
  }
  48% {
    opacity: 1;
  }
  to {
    opacity: 1;
    background-color: #fff;
    color: #000;
  }
}

.contact-socials {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.quick-social-contact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 25px;
}

.quick-social-contact a {
  font-size: 30px;
}

.footer {
  width: 100%;
  margin: 0.5rem auto;
  text-align: center;
}

.contact-footer {
  padding: 0 1rem 1rem 1rem;
  text-align: center;
}

.footer p {
  padding: 0;
  margin: 0;
}

.name-span {
  cursor: pointer;
}

.resume {
  padding: 0 1rem 1rem 1rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.success-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  margin: 0px auto 30px auto;
  padding: 0;
}

.success-form p {
  text-align: center;
  font-size: 1.2rem;
  margin: 10px auto;
}

.yellow-stroke {
  stroke: var(--yellow-theme);
}

.green-stroke {
  stroke: var(--green-theme);
}

.red-stroke {
  stroke: var(--red-theme);
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 8;
  stroke-miterlimit: 10;
  fill: var(--main-theme);
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 2% auto;
  box-shadow: 0px 0px 0px;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}

@media (max-width: 767px) {
  .footer p {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }

  .contact-label {
    width: 70vw;
    min-width: 265px;
  }
  textarea {
    width: 70vw;
    min-width: 265px;
  }
}

@media (max-width: 1200px) {
  .contact-form {
    margin: 0 auto;
  }

  .resume {
    margin: 0 auto;
  }
}
